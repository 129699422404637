import { useMutation } from '@tanstack/react-query';
import { personApi } from 'service/api/personApi';
import { BasicData, BasicDataPayload } from 'type/basicData';
import { ErrorRequestEntity } from '@dvag/dfs-api-client/interface';
import { Person } from 'type/person';
import { getSmokerOption, transformEmptyToNull } from 'utils/util';
import { useNotification } from 'utils/notification/NotificationContext';
import { queryClient } from 'config/reactQuery';

export const useUpdateBasicData = (
  personId: string | undefined,
  householdId: string | undefined,
  order: number | undefined,
) => {
  const { showError } = useNotification();
  const personService = personApi();
  return useMutation<BasicData, ErrorRequestEntity, BasicData, unknown>({
    mutationFn: async (formData) => {
      const { smoker, familyRelationship, ...restFormData } = formData;
      const mappedData = transformEmptyToNull(restFormData) as Person;
      const payload: BasicDataPayload = {
        householdId,
        familyRelationship,
        isSmoker: getSmokerOption(smoker),
        academicTitle: mappedData?.academicTitle,
        allFirstNames: mappedData?.allFirstNames,
        birthDate: mappedData?.birthDate,
        birthName: mappedData?.birthName,
        countryOfBirth: mappedData.countryOfBirth,
        firstName: mappedData?.firstName,
        lastName: mappedData?.lastName,
        maritalState: mappedData?.maritalState,
        nationality: mappedData.nationality,
        noOfChildren: mappedData?.noOfChildren,
        placeOfBirth: mappedData?.placeOfBirth,
        salutation: mappedData?.salutation,
      };
      const response = await personService.updatePerson(`${personId}`, payload);

      return response.data;
    },
    onError: (error) => {
      showError({ status: error?.response?.status });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['getBasicData', { personId, householdId }], {
        person: {
          basicData: { ...data, order },
        },
      });
    },
  });
};
