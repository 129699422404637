import { useAutosave } from '@dvag/dfs-ui-blocks/hooks';
import {
  DxCard,
  DxCardContent,
  DxCheckbox,
  DxContainer,
  DxDateInput,
  DxDropdown,
  DxGrid,
  DxNumberInput,
  DxTextInput,
} from '@dvag/design-system-react';
import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { mainPersonChecker, mandatoryFieldMessage } from 'form/util';
import { useMemoizedDropdownOptionList } from 'hooks/useMemoizedDropdownOptionList';
import { useUpdateBasicData } from 'hooks/useUpdateBasicData';
import { BasicData } from 'type/basicData';
import { isFieldRequired } from 'utils/common';
import {
  FieldType,
  FormType,
  FormTypes,
  SecondaryFormType,
  TriggerValidationValue,
} from 'utils/fieldList';
import { ContinutationRequest } from 'utils/useContinuation';
import { familyRelationshipList, isClient } from 'utils/util';
import {
  futureBirthDateError,
  oldDataError,
  validateGreaterThenCurrentDate,
  validateMinDate,
} from 'utils/validation';
import { checkIsMobile } from 'utils/windowSize';
import './style.css';
import { countryList } from '../utils/countries';
import { ComboboxSearch } from './ComboboxLocalData/ComboboxSearch';

interface BasicDataFormProps {
  display: string;
  personId: string | undefined;
  householdId: string | undefined;
  onFormDirtyStatusChanged: (formType: FormTypes, isDirty: boolean) => void;
  onFormValidStatusChanged: (formType: FormTypes, isValid: boolean) => void;
  onFormSubmittingStatusChanged: (formType: FormTypes, isSubmitting: boolean) => void;
  onFormSaved: (formType: FormTypes) => void;
  getCanContinueHandler: () => (canContinueCond: boolean) => void;
  onContinuationRequest: (continuationRequestCallback: ContinutationRequest) => void;
  data: BasicData;
  order: number | undefined;
  setTriggerValidationForms: (
    value: TriggerValidationValue,
    formType: FormTypes,
    secondaryFormType?: SecondaryFormType,
  ) => void;
}

const translation = {
  inputDefaultPlaceholder: i18next.t('general.inputDefaultPlaceholder'),
  selectDefaultPlaceholder: i18next.t('general.selectDefaultPlaceholder'),
  salutation: i18next.t('basicData.salutation'),
  child: i18next.t('basicData.child'),
  firstName: i18next.t('basicData.firstName'),
  lastName: i18next.t('basicData.lastName'),
  academicTitle: i18next.t('basicData.academicTitle'),
  middleName: i18next.t('basicData.middleName'),
  maidenName: i18next.t('basicData.maidenName'),
  birthDate: i18next.t('basicData.birthDate'),
  placeOfBirth: i18next.t('basicData.placeOfBirth'),
  birthCountry: i18next.t('basicData.birthCountry'),
  nationality: i18next.t('basicData.nationality'),
  maritalState: i18next.t('basicData.maritalState'),
  numberOfChildren: i18next.t('basicData.numberOfChildren'),
  smoker: i18next.t('basicData.smoker'),
};

export const BasicDataForm = ({
  display,
  personId,
  householdId,
  onFormDirtyStatusChanged,
  onFormValidStatusChanged,
  onFormSubmittingStatusChanged,
  onFormSaved,
  getCanContinueHandler,
  onContinuationRequest,
  data,
  order,
  setTriggerValidationForms,
}: BasicDataFormProps) => {
  const updateBasicData = useUpdateBasicData(personId, householdId, data.order);

  const isMobile = checkIsMobile();

  const isDisabled = isClient(data?.status);
  const {
    academicTitleListMemoized,
    smokerOptionsListMemoized,
    maritalStateMemoized,
    salutationListMemoized,
  } = useMemoizedDropdownOptionList();

  const isMainPerson = mainPersonChecker(order);

  const onCanContinueChange = useMemo(() => getCanContinueHandler(), [getCanContinueHandler]);

  const noOfChildErrorMessage = i18next.t('general.noOfChildrenError');

  const resourceSchema = useMemo(
    () =>
      yup.object().shape({
        salutation: yup.string().required(mandatoryFieldMessage),
        firstName: yup.string().required(mandatoryFieldMessage),
        lastName: yup.string().required(mandatoryFieldMessage),
        birthDate: yup
          .string()
          .required(mandatoryFieldMessage)
          .test('validateBirthDateFutureCase', futureBirthDateError, validateGreaterThenCurrentDate)
          .test('validateBirthDatePastCase', oldDataError, validateMinDate),
        placeOfBirth: isMainPerson
          ? yup.string().required(mandatoryFieldMessage)
          : yup.string().nullable(),
        countryOfBirth: isMainPerson
          ? yup.string().required(mandatoryFieldMessage)
          : yup.string().nullable(),
        noOfChildren: yup.number().min(0, noOfChildErrorMessage).nullable(),
        smoker: isMainPerson
          ? yup.string().required(mandatoryFieldMessage)
          : yup.string().nullable(),
      }),
    [isMainPerson, noOfChildErrorMessage],
  );

  const { register, setValue, flushChanges, getValues, triggerValidation, errors } =
    useAutosave<BasicData>({
      data,
      updateResource: updateBasicData.mutateAsync,
      onIsValidChange: (isValidParam) => {
        onFormValidStatusChanged(FormType.basicData, isValidParam);
      },
      onSubmitSuccessfulChange: (isSubmitSuccessfulParam) => {
        if (isSubmitSuccessfulParam) onFormSaved(FormType.basicData);
      },
      onIsDirtyChange: (isDirtyParam) => {
        onFormDirtyStatusChanged(FormType.basicData, isDirtyParam);
        onCanContinueChange(!isDirtyParam);
      },
      onIsSubmittingChange: (isSubmittingParam) => {
        onFormSubmittingStatusChanged(FormType.basicData, isSubmittingParam);
      },
      resourceSchema,
    });

  useEffect(() => {
    onContinuationRequest(() => {
      flushChanges();
      triggerValidation();
    });
  }, [flushChanges, onContinuationRequest, triggerValidation]);

  useEffect(() => {
    setTriggerValidationForms(triggerValidation as TriggerValidationValue, FormType.basicData);
  }, [setTriggerValidationForms, triggerValidation]);

  const currentSectionErrorList: DeepMap<FieldValues, FieldError> = errors;

  return (
    <DxCard
      className="pd_basicData-container"
      data-testid="pd_basicData-container"
      style={{ display }}
    >
      <DxCardContent
        className="pd_basicData-form-container"
        style={{ padding: isMobile ? '24px 16px 32px 16px' : '24px 24px 32px 24px' }}
      >
        <form className="pd_basic-data-form">
          <DxGrid base="page" mq3="6-6/6-6/6-6/6/6-6/6-6/6-6/6/*" mq1="12/*">
            <DxDropdown
              clearable={false}
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              id="pd_salutation__input"
              data-testid="pd_salutation__input"
              label={translation.salutation}
              disabled={isDisabled}
              required
              {...register(FieldType.salutation)}
            >
              {salutationListMemoized}
            </DxDropdown>
            <DxContainer color="transparent" className="pd_basicData-familyRelationship-container">
              <DxCheckbox
                className="pd_basicData-familyRelationship"
                id="pd_child"
                data-testid="pd_child"
                label={translation.child}
                disabled={isDisabled || isMainPerson}
                checked={getValues(FieldType.familyRelationship) === familyRelationshipList.KIND}
                {...register(FieldType.familyRelationship)}
                onCheckedChange={(e) => {
                  setValue(
                    FieldType.familyRelationship,
                    e.detail ? familyRelationshipList.KIND : familyRelationshipList.ERWACHSEN,
                  );
                }}
              />
            </DxContainer>
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              id="pd_firstName"
              data-testid="pd_firstName"
              required
              label={translation.firstName}
              disabled={isDisabled}
              maxlength={20}
              {...register(FieldType.firstName)}
            />
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              id="pd_lastName"
              data-testid="pd_lastName"
              required
              label={translation.lastName}
              disabled={isDisabled}
              maxlength={30}
              {...register(FieldType.lastName)}
            />
            <DxDropdown
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              id="pd_academicTitle"
              data-testid="pd_academicTitle"
              label={translation.academicTitle}
              {...register(FieldType.academicTitle)}
            >
              {academicTitleListMemoized}
            </DxDropdown>
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              id="pd_allFirstNames"
              data-testid="pd_allFirstNames"
              label={translation.middleName}
              maxlength={80}
              {...register(FieldType.allFirstNames)}
            />
            <DxTextInput
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              id="pd_birthName"
              nominmaxlabel
              data-testid="pd_birthName"
              label={translation.maidenName}
              maxlength={30}
              {...register(FieldType.birthName)}
            />
            <DxDateInput
              size="m"
              id="pd_birthDate"
              data-testid="pd_birthDate"
              required
              label={translation.birthDate}
              disabled={isDisabled}
              {...register(FieldType.birthDate)}
            />
            <DxTextInput
              nominmaxlabel
              size="m"
              placeholder={translation.inputDefaultPlaceholder}
              data-testid="pd_placeOfBirth"
              id="pd_placeOfBirth"
              required={
                isMainPerson || isFieldRequired(FieldType.placeOfBirth, currentSectionErrorList)
              }
              label={translation.placeOfBirth}
              maxlength={30}
              {...register(FieldType.placeOfBirth)}
            />
            <ComboboxSearch
              data={countryList}
              errormessage={register(FieldType.countryOfBirth).errormessage}
              isDisabled={isDisabled}
              onSelectSearchOption={(value) => {
                setValue(FieldType.countryOfBirth, value);
              }}
              isRequired={
                isMainPerson || isFieldRequired(FieldType.countryOfBirth, currentSectionErrorList)
              }
              value={register<string>(FieldType.countryOfBirth).value}
              label={translation.birthCountry}
              id="pd_countryOfBirth"
            />
            <ComboboxSearch
              data={countryList}
              errormessage={register(FieldType.nationality).errormessage}
              isDisabled={isDisabled}
              onSelectSearchOption={(value) => {
                setValue(FieldType.nationality, value);
              }}
              value={register<string>(FieldType.nationality).value}
              label={translation.nationality}
              id="pd_nationality__input"
            />
            <DxDropdown
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              id="pd_maritalState"
              data-testid="pd_maritalState"
              label={translation.maritalState}
              {...register(FieldType.maritalState)}
            >
              {maritalStateMemoized}
            </DxDropdown>
            <DxNumberInput
              size="m"
              data-testid="pd_noOfChildren"
              id="pd_noOfChildren"
              label={translation.numberOfChildren}
              limitlength={2}
              {...register(FieldType.noOfChildren)}
            />
            <DxDropdown
              size="m"
              placeholder={translation.selectDefaultPlaceholder}
              id="pd_smoker"
              data-testid="pd_smoker"
              required={isMainPerson || isFieldRequired(FieldType.smoker, currentSectionErrorList)}
              label={translation.smoker}
              {...register(FieldType.smoker)}
            >
              {smokerOptionsListMemoized}
            </DxDropdown>
          </DxGrid>
        </form>
      </DxCardContent>
    </DxCard>
  );
};
